.dest-container {
  position: relative;
  height: 100vh;
}
.dest-container img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.dest-container .hero-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.dest-container .bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.overview-container {
  padding: 50px 100px;
}
.overview-container p {
  text-align: justify;
  white-space: pre-wrap;
}

/* GALLERY  */
.gallery-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.gallery-container img {
  width: 100%;
  height: 800px;
  object-fit: cover;
}
.gallery-container .icon {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-size: 100px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.gallery-container .icon:hover {
  background-color: #fff;
  color: #000;
}
.gallery-container .gla {
  left: 30px;
}
.gallery-container .gra {
  right: 30px;
}

/* USEFUL INFORMATION */
.useful-info-container {
  padding: 50px;
  text-align: center;
  font-size: 20px;
}
.useful-info {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--three);
  padding: 20px;
  overflow-y: scroll;
  transition: 0.5s all ease;
}
.useful-info.active {
  top: 0;
}
.useful-info .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 50px;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    scale: 1.2;
  }
}
.useful {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-between;
}
.useful-info h1 {
  width: 100%;
}
.useful-info .description {
  width: 48%;
  font-size: 15px;
  text-align: start;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.useful-info-container button {
  padding: 20px 50px;
}

/*! ITINERARY  */
.itinerary {
  padding: 100px 200px;
  background-color: var(--three);
}
.accordion-item-day {
  background-color: #eee !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 10px !important;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  height: auto;
  width: 70px;
}
.itinerary-item {
  margin-bottom: 10px !important;
  background-color: transparent;
}
.itinerary-item button:focus {
  border: 0px !important;
  color: #000;
}
.itinerary .accordion-item-div {
  background-color: #fff;
  border-radius: 10px;
}
.itinerary .accordion-body {
  background-color: #fff;
}
.accordion-item-div span {
  font-weight: 600;
  font-size: 15px;
}

/* =====  MEDIA QUERY*/
@media screen and (max-width: 992px) {
  .dest-container {
    height: 55vh;
  }
  .overview-container {
    padding: 80px 20px;
  }
  .dest-container img {
    width: 100%;
    height: 55vh;
  }
}
@media screen and (max-width: 786px) {
  .gallery-container img {
    height: 300px;
  }
  .gallery-container .icon {
    font-size: 50px;
  }
  .itinerary {
    padding: 80px 20px;
  }
  .useful-info {
    padding-top: 50px;
  }
  .useful-info .description {
    width: 100%;
  }
  .useful {
    margin-top: 20px;
  }
  .useful-info-container button {
    font-size: 15px;
  }
}
