.destinations-container {
  padding: 100px;
  overflow: hidden;
}
.destinations-slider-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.destinations-slider-btns .icon {
  border: 2px solid var(--two);
  color: var(--two);
  font-size: 80px;
  padding: 10px;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.destinations-slider-btns .icon:hover {
  background-color: var(--two);
  color: #fff;
}

.destination {
  padding: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.destination h4 {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 35px;
  z-index: 20;
}
.dest {
  overflow: hidden;
  border-radius: 25px;
}
.dest img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 25px;
  position: relative;
  transition: 0.5s all ease-out;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  scale: 1;
}
.dest img:hover {
  scale: 1.2;
}
.dest img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .destinations-container {
    padding: 80px 20px;
  }
}
