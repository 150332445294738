.header-main {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: 0.5s all ease;
  overflow: hidden;
}
.header-main.active {
  background-color: #000;
}

.logo {
  cursor: pointer;
}
.logo img {
  width: 250px;
}

.header-main ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  li {
    /* font-size: 15px; */
    cursor: pointer;
    transition: all 1s ease-out;
    &:hover {
      color: orange;
    }
  }
}

.header-main .icon {
  font-size: 50px;
  margin-left: 20px;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .header-main {
    padding: 20px;
  }
}
