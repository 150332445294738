@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
@font-face {
  font-family: myFont;
  src: url("fonts/cursive.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none !important;
  text-decoration: none !important;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
}
h1,
h2 {
  font-family: "Playfair Display", serif;
}
.cursive {
  font-family: "myFont";
}
:root {
  --p: #eee;
  --s: #fff;
  --t: #ffca00;
  --one: #16160f;
  --two: #6f624a;
  --three: #f5f5dc;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}

.theme-btn {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  outline: transparent;
  border: transparent;
}
.theme-btn:hover {
  opacity: 0.8;
}
.theme-btn-2 {
  background-color: var(--one);
  color: #fff;
  padding: 15px 30px;
  outline: transparent;
  border: 2px solid var(--one);
  transition: 0.5s all ease;
}
.theme-btn-2:hover {
  background-color: #fff;
  border: 2px solid #fff;
  color: #000;
}
.hero-title {
  font-size: 80px;
}
.hrline {
  position: relative;
  margin: 30px 0;
}
.hrline:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 100px;
  height: 3px;
  background-color: var(--two);
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}

@media screen and (max-width: 786px) {
  .hero-title {
    font-size: 60px;
  }
}
@media screen and (max-width: 400px) {
  .hero-title {
    font-size: 35px;
  }
}
