.home-services-container {
  padding: 100px 200px;
  background: url("../../img/bg.png");
  background-position: center;
  background-size: cover;
  color: #fff;
  position: relative;
}
.home-services-container h1 {
  color: #000;
}
.home-services-container .icon,
.home-services-container .icon2 {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff !important;
  font-size: 700px;
  opacity: 0.05;
  z-index: 10;
}
.home-services-container img {
  width: 100% !important;
  border-radius: 25px;
  overflow: hidden;
}
.home-services-container h2 {
  color: #000;
  cursor: pointer;
  z-index: 20;
  transition: 0.5s all ease;
}
.home-services-container .active {
  color: #666;
}
.ser-img {
  position: relative;
}
.ser-img p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
}
.ser-img .theme-btn {
  background-color: var(--three);
  padding: 8px 20px;
  color: #000;
}
.ser-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  border-radius: 25px;
  z-index: 10;
}
.ser-text {
  position: absolute;
  left: 25px;
  bottom: 30px;
  text-align: start;
  z-index: 20;
}
@media screen and (max-width: 786px) {
  .home-services-container {
    padding: 80px 20px;
  }
}
