.home-videos {
  padding: 100px 200px;
  background-color: #000;
}
.home-videos .cursive {
  font-size: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
}
.home-videos .cursive span {
  color: var(--two);
}
.h-videos {
  margin-top: 30px;
}
.h-videos iframe {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  margin-top: 30px;
}
@media screen and (max-width: 786px) {
  .home-videos {
    padding: 80px 20px;
  }
  .home-videos .cursive {
    font-size: 40px;
  }
}
