.exp-container {
  background: url("../img/exp/exper.jpeg");
  background-size: cover;
  background-position: center;
}
.experience-slider {
  padding: 100px 200px;
  padding-top: 50px;
  position: relative;
  background-color: var(--three);
  overflow: hidden;
}
.experiences-box {
  padding: 10px;
  cursor: pointer;
}
.experiences {
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #fff;
}
.experiences p {
  padding: 10px;
  margin: 0;
  font-weight: 500;
}
.experiences img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  scale: 1;
  transition: 0.5s all ease;
}
.experiences img:hover {
  scale: 1.02;
}
.exp-slider-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.exp-slider-btn .exp-slider-left,
.exp-slider-btn .exp-slider-right {
  font-size: 40px;
  border-radius: 100px;
  transition: 0.5s all ease;
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    background-color: var(--two);
    color: #fff;
    border: 1px solid var(--two);
  }
}

@media screen and (max-width: 992px) {
  .experience-slider {
    padding: 50px 20px;
    padding-bottom: 100px;
  }
}
