.destinations {
  background: url("../img/destination/hawa.jpg");
  background-position: center;
  background-size: cover;
}
.dest-about {
  padding: 100px;
  background-color: var(--three);
}

@media screen and (max-width: 992px) {
  .dest-about {
    padding: 50px 20px;
  }
}
