.region-container h2 {
  margin: 20px 0;
}
.ser-cnt {
  position: relative;
  padding: 20px;
  background-color: var(--three);
  border-radius: 30px;
}
.ser-cnt p {
  text-align: justify;
  margin-top: 30px;
}
.ser-img {
  border-radius: 25px;
  overflow: hidden;
}
.ser-img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 25px;
}
.ser-img-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 99;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  padding: 20px;
}
.ser-img-slider .la,
.ser-img-slider .ra {
  font-size: 50px;
  border: 1px solid #fff;
  border-radius: 100px;
  cursor: pointer;
}
.ser-img-slider .la:hover,
.ser-img-slider .ra:hover {
  background-color: var(--three);
  color: #000;
}
