.how-to-plan {
  padding: 100px;
  background-color: var(--three);
}
.how-to-plan .icon {
  font-size: 50px;
  color: var(--two);
}
.how-to-plan .plan-heading {
  font-weight: 800;
  font-size: 18px;
}
.plan {
  padding: 0 30px;
}

@media screen and (max-width: 786px) {
  .how-to-plan {
    padding: 80px 20px;
  }
}
