.login-container {
  padding-top: 200px !important;
  background: url("../img/service/service.webp");
  background-size: cover;
  background-position: center;
}

.login-container .form {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
