.sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  z-index: 999;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidemenu-container.active {
  left: 0;
  opacity: 1;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.sidemenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.sidemenu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 50px !important;
}

.sidemenu ul li {
  font-size: 50px;
}
.bottom-social {
  border-top: 2px solid #fff;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 100px;
  width: 80%;
  padding: 10px;
}
.bottom-social a {
  color: #fff;
}

@media screen and (max-width: 992px) {
  .sidemenu ul li {
    font-size: 30px;
  }
}
