.contact-container {
  background-image: url("../img/contact/contact.jpeg");
  background-position: center;
  background-size: cover;
}
.office-address-container {
  padding: 100px 200px;
  display: flex;
  flex-wrap: wrap;
}
.office-address-container h1 {
  width: 100%;
}
.office-address-container .address {
  width: 30%;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s all ease;
  text-wrap: wrap;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
  margin: 10px;
}
.address h4 {
  margin: 0;
  padding-bottom: 10px;
}
.address p {
  margin: 0;
  white-space: pre-wrap;
}
.office-address-container .address:hover {
  background-color: var(--three);
}
.contact-form {
  background-color: var(--three);
  padding: 100px 200px;
  text-align: center;
}

.react-tel-input input {
  width: 100% !important;
  height: 45px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 992px) {
  .contact-form {
    padding: 80px 20px;
    text-align: center;
  }
  .office-address-container {
    padding: 80px 20px;
  }
  .office-address-container .address {
    margin: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 786px) {
  .office-address-container .address {
    width: 100%;
  }
  .office-address-container {
    padding: 80px 30px;
  }
}
