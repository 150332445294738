.service-container {
  background: url("../img/service/servide.jpeg");
  background-position: bottom;
  background-size: cover;
}
.about-service {
  padding: 100px;
}
.about-service p {
  text-align: justify;
}
.service {
  width: 100%;
  overflow: hidden;
}
.service .cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 50px;
}
.service .cont p {
  text-align: justify;
}
.service .images {
  height: 80vh;
  background-size: cover;
  background-position: center;
  padding: 0;
}
.service .one {
  background: url("../img/service/suit.jpg");
  background-size: cover;
  background-position: center;
}
.service .two {
  background: url("../img/home/exorted.jpg");
  background-size: cover;
  background-position: center;
}
.service .three {
  background: url("../img/home/luxury.png");
  background-size: cover;
  background-position: center;
}
.service .four {
  background: url("../img/home/special.jpg");
  background-size: cover;
  background-position: center;
}
.service .five {
  background: url("../img/home/healing.jpg");
  background-size: cover;
  background-position: center;
}
.service .six {
  background: url("../img/home/cruise.jpeg");
  background-size: cover;
  background-position: center;
}
.service .seven {
  background: url("../img/home/trains.jpg");
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 992px) {
  .about-service {
    padding: 80px 20px;
  }
}
@media screen and (max-width: 786px) {
  .service .images {
    height: 50vh;
    background-position: top;
  }

  .service .cont {
    padding: 30px;
  }
}
